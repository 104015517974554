export default function readOnlyElements(exeption) {
  let buttons = document.getElementsByTagName("button");
  let inputs = document.getElementsByTagName("input");
  let selects = document.getElementsByTagName("select");
  let aTags = document.getElementsByTagName("a");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].setAttribute("disabled", true);
  }
  for (let x = 0; x < selects.length; x++) {
    selects[x].setAttribute("disabled", true);
  }
  for (let y = 0; y < buttons.length; y++) {
    if (
      buttons[y].className.length &&
      !buttons[y].className.includes("accordion-button") &&
      !buttons[y].className.includes("swal") &&
      buttons[y].name != exeption
    )
      buttons[y].setAttribute("disabled", true);
  }
  for (let z = 0; z < selects.length; z++) {
    aTags[z].setAttribute("disabled", true);
    // aTags[z].style.pointerEvents("none");
  }
}
