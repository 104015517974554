<template>
  <div class="text-end">
    <router-link
      :to="`/impianti/omologazioni-impianto/${id_impianto}`"
      class="btn btn-light text-gray-700 fs-6 text-start"
    >
      <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista omologazioni
      impianto
    </router-link>
  </div>
  <AddOmologazione :id_imp="id_impianto" :fromImpianto="true" />
</template>

<script>
import { ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddOmologazione from "@/components/components-fit/impianti/AddOmologazione.vue";
import { useRoute } from "vue-router";

export default {
  components: { AddOmologazione },
  setup() {
    const route = useRoute(); // prende id
    const id_impianto = ref(route.params.id_impianto);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Nuova Omologazione", ["Lista Omologazioni"]);
    });
    return { id_impianto };
  },
};
</script>

<style></style>
