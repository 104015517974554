import services from "../axios/dbManag";

export const detailImpiantoOmologazione = (
  id_impianto,
  id_disciplina,
  id_omologazione_impianti
) => {
  return services.dbManag
    .post("/omologazioni/impianti/view", {
      id_impianto,
      id_disciplina,
      id_omologazione_impianti,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};
export const detailImpiantoOmologazioneV2 = (
  id_impianto,
  id_disciplina,
  id_omologazione_impianti
) => {
  return services.dbManagV2
    .post("/omologazioni/impianti/view", {
      id_impianto,
      id_disciplina,
      id_omologazione_impianti,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addOmologazione = (omologazione) => {
  return services.dbManag
    .post("/omologazioni/impianti/add", {
      ...omologazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const addOmologazioneV2 = (omologazione) => {
  return services.dbManagV2
    .post("/omologazioni/impianti/add", {
      ...omologazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editOmologazione = (omologazione) => {
  return services.dbManag
    .post("/omologazioni/impianti/edit", {
      ...omologazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const editOmologazioneV2 = (omologazione) => {
  return services.dbManagV2
    .post("/omologazioni/impianti/edit", {
      ...omologazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const inCaricoSiNoOmologazione = (id, azione) => {
  return services.dbManag
    .post("/omologazioni/impianti/prendincarico", {
      id,
      azione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const attesaVerificaOmologazione = (id) => {
  return services.dbManag
    .post("/omologazioni/impianti/attesaverifica", {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const annullaApprovazioneOmologazione = (id_omologazione_impianti) => {
  return services.dbManag
    .post("/omologazioni/impianti/annulla/approvazione", {
      id_omologazione_impianti: id_omologazione_impianti,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const approvaOmologazione = (id, id_Impianto) => {
  return services.dbManag
    .post("/omologazioni/impianti/approva", {
      id,
      id_Impianto,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const rifiutaOmologazione = (id, id_Impianto) => {
  return services.dbManag
    .post("/omologazioni/impianti/rifiuta", {
      id,
      id_Impianto,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const changeLivelloOmologazione = (
  id_omologazione_impianti,
  id_livello_omologazione
) => {
  return services.dbManag
    .post("/omologazioni/impianti/livello/edit", {
      id_omologazione_impianti,
      id_livello_omologazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
